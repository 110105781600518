import '@elastic/eui/dist/eui_theme_light.min.css'
import './App.css'

import Logo from './images/logo.png'

import a from './images/a.jpg'
import b from './images/b.jpg'
import c from './images/c.jpg'
import d from './images/d.jpg'
import e from './images/e.jpg'

import { EuiPage, EuiPageBody, EuiSpacer, EuiProvider, EuiPanel, EuiFlexItem, EuiFlexGroup, EuiTitle, EuiText, EuiHorizontalRule, EuiPageContent, EuiBadge, EuiIcon, EuiShowFor } from "@elastic/eui";
import React from 'react'

const App = () => {
  const hvac = ['Boiler Plants','Con Edison Steam Station Design ','Chiller Plants ','Cooling Tower systems ','Variable Refrigerant Flow Heat Pump systems','Energy Recovery systems ']
  const electrical = ['Power distribution', 'Lighting systems', 'Fire Alarm systems']
  const plumbing = ['Domestic water systems', 'Sanitary drainage', 'Gas distribution', 'Storm drainage']
  const fire = ['Sprinkler systems', 'Smoke detection and alarms']
  const additional = ['DOB Filing and Expediting', 'Special Inspections', 'Commissioning and Retro-commissioning', 'MEP Design Reviews' ]

  const sections = [
    {section: 'HVAC', services: hvac, image: a},
    {section: 'Electrical', services: electrical, image: b},
    {section: 'Plumbing', services: plumbing, image: c},
    {section: 'Fire Protection', services: fire, image: d},
    {section: 'Additional Services', services: additional, image: e}
  ]

  return (
    <EuiProvider>
    <EuiPage paddingSize='none' style={{minHeight: '100vh', minWidth: '99vw', fontFamily: 'Quicksand', }} className=''>
    <EuiPageBody paddingSize='none' panelled={false} style={{width: '100%'}}>
    <EuiPageContent horizontalPosition='center' color='transparent' paddingSize='none' style={{width: '100%', overflow: 'hidden'}}>


      {/* ------------------------------------------------------------------------ */}

      <EuiShowFor sizes={['xs', 's', 'm']}>
        <EuiFlexGroup justifyContent='center' alignItems='center' style={{marginTop: '0vh'}}>
          <EuiFlexItem grow={false} style={{maxWidth: '15vw'}}>
            <img src={Logo} alt='Newtec Engineering' width={'100%'}/>
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{maxWidth: '80vw'}}>
            <EuiTitle size='s'><h1 style={{fontSize: '4vh', fontFamily: 'Quicksand', color: '#090076'}}>newtec engineering</h1></EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiShowFor sizes={['l', 'xl']}>
        <EuiFlexGroup justifyContent='center' alignItems='center' style={{marginTop: '2vh'}}>
          <EuiFlexItem grow={false} style={{maxWidth: '10vw'}}>
            <img src={Logo} alt='Newtec Engineering' width={'100%'} style={{alignSelf: 'end'}}/>
          </EuiFlexItem>
          <EuiFlexItem grow={false} style={{maxWidth: '80vw'}}>
            <EuiTitle size='l'><h1 style={{fontSize: '6vh', fontFamily: 'Quicksand', color: '#090076'}}>newtec engineering</h1></EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      {/* ------------------------------------------------------------------------ */}
      <EuiPanel style={{marginTop: '4vh', paddingTop: '2vh', paddingBottom: '6vh', borderRadius: 0}} className='gradient boxshadow' color='transparent'>


      <EuiShowFor sizes={['xs', 's', 'm']}>
        <EuiFlexGroup justifyContent='center' alignItems='center' style={{paddingTop: '4vh'}}>
          <EuiFlexItem grow={false}>
            <EuiTitle className='eui-textCenter'><span style={{fontSize: '3vh', fontFamily: 'Quicksand', color: '#fafbfd'}}>Who we are</span></EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiHorizontalRule size='half'/>

        <EuiFlexGroup justifyContent='spaceAround' alignItems='center'>
          <EuiFlexItem grow={false}>
            <EuiText style={{fontSize: '2vh', fontFamily: 'Quicksand'}} className='eui-textCenter' color='#fafbfd'>
              We specialize in providing professional engineering services for HVAC, electrical, plumbing and fire protection design as well as in-house Department of Buildings (DOB) filings and expediting services.
            </EuiText>
            <EuiSpacer/>
            <EuiText style={{fontSize: '2vh', fontFamily: 'Quicksand'}} className='eui-textCenter' color='#fafbfd'>
              We are based in New Jersey and are licensed to design in <strong>New Jersey, New York, and Pennsylvania.</strong>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      <EuiShowFor sizes={['l', 'xl']}>
        <EuiFlexGroup justifyContent='spaceAround' alignItems='center' style={{paddingTop: '4vh'}}>
          <EuiFlexItem grow={false}>
            <EuiTitle ><span style={{fontSize: '3vh', fontFamily: 'Quicksand', color: '#fafbfd'}}>Who we are</span></EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiHorizontalRule size='half'/>

        <EuiFlexGroup justifyContent='spaceAround' alignItems='center'>
          <EuiFlexItem grow={false} style={{maxWidth: '40vw'}}>
            <EuiText style={{fontSize: '2vh', fontFamily: 'Quicksand'}} className='eui-textCenter' color='#fafbfd'>
              We specialize in providing professional engineering services for HVAC, electrical, plumbing and fire protection design as well as in-house Department of Buildings (DOB) filings and expediting services.
            </EuiText>
            <EuiSpacer/>
            <EuiText style={{fontSize: '2vh', fontFamily: 'Quicksand'}} className='eui-textCenter' color='#fafbfd'>
              We are based in New Jersey and are licensed to design in <strong>New Jersey, New York, and Pennsylvania.</strong>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiShowFor>

      </EuiPanel>

      {/* ------------------------------------------------------------------------ */}

      {/* <EuiPanel style={{marginTop: '4vh'}} paddingSize='l' borderRadius='none' color='transparent'> */}

      <EuiShowFor sizes={['xs', 's', 'm']}>
        <EuiFlexGroup justifyContent='center' alignItems='center' style={{paddingTop: '4vh'}}>
          <EuiFlexItem grow={false}>
            <EuiTitle className='eui-textCenter'><span style={{fontSize: '2.5vh', fontFamily: 'Quicksand', color: '#090076'}}>Newtec Engineering provides the following engineering design services:</span></EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiHorizontalRule size='half'/>

        {sections.map(s => {
          return(
            <React.Fragment>
            <EuiPanel paddingSize='none' color='transparent' className='boxshadow' style={{backgroundImage: "url("+s.image+")", backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}} grow>
              <EuiPanel style={{background: 'rgba(0, 0, 0, 0.7)'}} className='eui-fullHeight'>
              <EuiTitle><span style={{fontFamily: 'Inter', color: 'white'}}><strong>{s.section}</strong></span></EuiTitle>
              <EuiSpacer/>
              <EuiFlexGroup direction='column' alignItems='flexStart' gutterSize='xs'>
                  {s.services.map(s => <EuiFlexItem grow={false}><EuiBadge iconType='pinFilled' style={{fontSize: '1.5vh', fontFamily: 'Inter', fontWeight: 'bolder'}} color='hollow'>{s}</EuiBadge></EuiFlexItem>)}
              </EuiFlexGroup>
              </EuiPanel>
            </EuiPanel>
            <EuiSpacer/>
            </React.Fragment>
            )
        })}
      </EuiShowFor>

      <EuiShowFor sizes={['l', 'xl']}>

        <EuiFlexGroup justifyContent='spaceAround' alignItems='center' style={{paddingTop: '4vh'}}>
          <EuiFlexItem grow={false}>
            <EuiTitle ><span style={{fontSize: '3vh', fontFamily: 'Quicksand', color: '#090076'}}>Newtec Engineering provides the following engineering design services:</span></EuiTitle>
          </EuiFlexItem>
        </EuiFlexGroup>

        <EuiHorizontalRule size='half'/>

        <EuiFlexGroup justifyContent='center' alignItems='stretch' gutterSize='xl'>
          {sections.slice(0,3).map(s => {
            return(
              <EuiFlexItem grow={false} key={s.section} style={{maxHeight: '25vh', width: '20vw'}}>
              <EuiPanel paddingSize='none' color='transparent' className='boxshadow' style={{backgroundImage: "url("+s.image+")", backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}} grow>
                <EuiPanel style={{background: 'rgba(0, 0, 0, 0.7)'}} className='eui-fullHeight'>
                <EuiTitle><span style={{fontFamily: 'Inter', color: 'white'}}><strong>{s.section}</strong></span></EuiTitle>
                <EuiSpacer/>
                <EuiFlexGroup direction='column' alignItems='flexStart' gutterSize='s'>
                    {s.services.map(s => <EuiFlexItem grow={false}><EuiBadge iconType='pinFilled' style={{fontSize: '1.25vh', fontFamily: 'Inter', fontWeight: 'bolder'}} color='hollow'>{s}</EuiBadge></EuiFlexItem>)}
                </EuiFlexGroup>
                </EuiPanel>
              </EuiPanel>
              </EuiFlexItem>
              )
            })}
          </EuiFlexGroup>

          <EuiFlexGroup justifyContent='center' alignItems='stretch' gutterSize='xl'>
            {sections.slice(3,5).map(s => {
              return(
                <EuiFlexItem grow={false} key={s.section} style={{maxHeight: '25vh', width: '20vw'}}>
                <EuiPanel paddingSize='none' color='transparent' className='boxshadow' style={{backgroundImage: "url("+s.image+")", backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
                  <EuiPanel style={{background: 'rgba(0, 0, 0, 0.7)'}} className='eui-fullHeight'>
                  <EuiTitle><span style={{fontFamily: 'Inter', color: 'white'}}><strong>{s.section}</strong></span></EuiTitle>
                  <EuiSpacer/>
                  <EuiFlexGroup direction='column' alignItems='flexStart' gutterSize='s'>
                      {s.services.map(s => <EuiFlexItem grow={false}><EuiBadge iconType='pinFilled' style={{fontSize: '1.25vh', fontFamily: 'Inter', fontWeight: 'bolder'}} color='hollow'>{s}</EuiBadge></EuiFlexItem>)}
                  </EuiFlexGroup>
                  </EuiPanel>
                </EuiPanel>
                </EuiFlexItem>
              )
            })}

          </EuiFlexGroup>
        </EuiShowFor>
      {/* ------------------------------------------------------------------------ */}



        <EuiPanel color='plain' style={{marginTop: '4vh', color: 'white', paddingTop: '4vh', borderRadius: 0}} className='gradient gray'>

          <EuiShowFor sizes={['xs', 's', 'm']}>
            <EuiFlexGroup justifyContent='spaceAround' alignItems='center'>
              <EuiFlexItem grow={false}>
                <EuiTitle className='eui-textCenter'><span style={{fontSize: '3vh', fontFamily: 'Quicksand', color: 'white'}}>Let's build a solution together.</span></EuiTitle>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiHorizontalRule size='quarter'/>

            <EuiFlexGroup justifyContent='center' alignItems='center' gutterSize='none'>

              <EuiFlexItem grow={false}>
                <EuiText size='relative' style={{fontSize: '2vh'}} className='eui-textCenter'>150 River Rd.</EuiText>
                <EuiText size='relative' style={{fontSize: '2vh'}} className='eui-textCenter'>Building N, Unit 2B</EuiText>
                <EuiText size='relative' style={{fontSize: '2vh'}} className='eui-textCenter'>Montville, NJ 07045</EuiText>
              </EuiFlexItem>

              <EuiFlexItem grow={false}/>

              <EuiFlexItem grow={false}>
                <EuiText size='relative' style={{fontSize: '2vh'}} className='eui-textCenter'><a href='https://www.google.com/maps/place/150+River+Rd,+Montville,+NJ+07045/' style={{color: 'white'}}><EuiIcon type='mapMarker' size='l'/>Directions</a></EuiText>
                <EuiText size='relative' style={{fontSize: '2vh'}} className='eui-textCenter'><a href='tel:973-299-8417' style={{color: 'white'}}><EuiIcon type='mobile' size='l'/> 973-299-8417</a></EuiText>
                <EuiText size='relative' style={{fontSize: '2vh'}} className='eui-textCenter'><a href='mailto:newtec@optonline.net' style={{color: 'white'}}><EuiIcon type='email' size='l'/> newtec@optonline.net</a></EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size='xxl'/>
            <EuiText size='xs' className='eui-textCenter'>Newtec Engineering | Copyright 2022 | All rights reserved.</EuiText>
          </EuiShowFor>

          <EuiShowFor sizes={['l', 'xl']}>
            <EuiFlexGroup justifyContent='spaceAround' alignItems='center'>
              <EuiFlexItem grow={false} style={{maxWidth: '60vw'}}>
                <EuiTitle ><span style={{fontSize: '2.75em', fontFamily: 'Quicksand', color: 'white'}}>Let's build a solution together.</span></EuiTitle>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiHorizontalRule size='quarter'/>

            <EuiFlexGroup justifyContent='center' alignItems='center' gutterSize='xl'>

              <EuiFlexItem grow={false}>
                <EuiText size='relative' style={{fontSize: '2em'}} className='eui-textCenter'>150 River Rd.</EuiText>
                <EuiText size='relative' style={{fontSize: '2em'}} className='eui-textCenter'>Building N, Unit 2B</EuiText>
                <EuiText size='relative' style={{fontSize: '2em'}} className='eui-textCenter'>Montville, NJ 07045</EuiText>
              </EuiFlexItem>

              <EuiFlexItem grow={false}/>

              <EuiFlexItem grow={false}>
                <EuiText size='relative' style={{fontSize: '2em'}} className='eui-textLeft'><a href='https://www.google.com/maps/place/150+River+Rd,+Montville,+NJ+07045/' style={{color: 'white'}}><EuiIcon type='mapMarker' size='xl'/> Directions</a></EuiText>
                <EuiText size='relative' style={{fontSize: '2em'}} className='eui-textLeft'><a href='tel:973-299-8417' style={{color: 'white'}}><EuiIcon type='mobile' size='xl'/> 973-299-8417</a></EuiText>
                <EuiText size='relative' style={{fontSize: '2em'}} className='eui-textLeft'><a href='mailto:newtec@optonline.net' style={{color: 'white'}}><EuiIcon type='email' size='xl'/> newtec@optonline.net</a></EuiText>
              </EuiFlexItem>
            </EuiFlexGroup>

            <EuiSpacer size='xxl'/>
            <EuiText size='xs' className='eui-textCenter'>Newtec Engineering | Copyright 2022 | All rights reserved.</EuiText>
          </EuiShowFor>




        </EuiPanel>

    </EuiPageContent>
    </EuiPageBody>
    </EuiPage>
    </EuiProvider>
  )
}

export default App